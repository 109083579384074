$bg-light-Color: #F4F4F4;
$bg-white: #fff;
$border-light: #DDDBDA;
$btn-yellow: #FFB500;

//tabs
$tab-active: #FFB500;

$font-12: 12px;
$font-16: 16px;
