/**
 * Styling for the event status badge component.
 * @module src/Shared/EventStatus
 */
.badge {
  background: #b83c27;
  border-radius: 12px;
  text-align: center;
  text-transform: uppercase;
  height: 18px;
  width: fit-content;
  min-width: 85px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .badge__title {
    font-family: Salesforce Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: #ffffff;
  }
}
.badge--new {
  background: #B83C27;
}
.badge--requested {
  background: #CF6E29;
}
.badge--shipped {
  background: #58B837;
}
.badge--portal {
  background: #4c7d87;
}
.badge--assigned {
  background: #FFB500;
}
.badge--completed {
  background: #2B72CC;
}
.badge--closed {
  background: #B2B4AE;
}
.badge--cancelled {
  background: #000000;
}
.badge--returning {
  background: #2B72CC;
}
.badge--approved {
  background: #2b72cc;
}
.badge--rejected {
  background: #B83C27;
}
.badge--submitted {
  background: #FFB500;
}
.badge--booked {
  background: #4c7d87;
}
.badge--complete {
  background: #2B72CC;
}
.badge--failed {
  background: lightgrey
}
.badge--recall {
  background: #B71010
}
.badge--inprogress {
  background: #CF6E29;
}
.badge--3pl {
  background: #85458a ;
}
