footer {
  &.footer-style-1 {
    background: #545857;
    padding: 42px 40px 60px;
    ul {
      li {
        color: #f4f4f4;
        position: relative;
        padding: 0px 10px;
        @media (max-width: 1024px) {
          font-size: 11px;
        }
        &:first-child {
          padding-left: 0px;
        }
        &:first-child:before {
          content: none;
        }
        &:before {
          content: '|';
          position: absolute;
          left: 0px;
        }
        a {
          color: #f4f4f4;
          font-family: Salesforce Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          @media (max-width: 1024px) {
            font-size: 11px;
          }
          &:hover {
            color: #f4f4f4;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.footer_container {
  margin-top: auto;
}
